@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $primary-bg;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 328px;
  margin: 100px 0;
  @include tablet {
    width: 592px;
  }
  @include laptop {
    width: 1200px;
    margin-bottom: 100px;
  }
}

.h5 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: $secondary-text-blue;
  margin: 0 0 10px;
  @include tablet {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 20px;
  }
  @include laptop {
    margin: 0 0 30px;
    font-size: 20px;
    line-height: 30px;
  }
}

.youtube {
  border: none;
}
