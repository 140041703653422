@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.mainBaner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("../../../public/assets/img/bg-main.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding-top: 77px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(70deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0));

    z-index: -1;
  }
  @include tablet{
    padding-top: 105px;

  }
  @include laptop{
    padding-top: 149px;

  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 328px;
  @include tablet {
    width: 592px;
  }
  @include laptop {
    width: 1200px;
  }
}

.width {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 302px;
  margin: 76px 0;
  @include tablet {
    width: 526px;
    margin: 144px 0;
  }
  @include laptop {
    width: 705px;
    margin: 170px 0;
  }
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 16px 0;
  text-align: center;
  @include tablet {
    font-size: 36px;
    margin: 0 0 20px 0;
    line-height: 46px;
  }
  @include laptop {
    font-size: 48px;
    line-height: 72px;
    margin: 0 0 24px 0;
  }
}

.text {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  opacity: 70%;
  margin: 0 0 24px 0;

  @include tablet {
    margin: 0 0 32px 0;
  }
  @include laptop {
    margin: 0 0 40px 0;
    font-size: 20px;
    line-height: 24px;

  }
}

.btn {
  background: $secondary-bg;
  border-radius: 30px;
  border: 0;
  color: $primary-text-white;
  padding: 12px 24px;
  @include tablet {
    padding: 16px 32px;
  }
}
