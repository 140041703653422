@import 'src/styles/variables';
@import 'src/styles/_mixins.scss';

.catalogs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background: $primary-bg;
  @include laptop {
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 328px;
  margin: 40px 0;
  @include tablet {
    width: 592px;
    margin: 50px 0;
  }
  @include laptop {
    width: 1200px;
    margin: 100px 0;
  }
}

.swiper {
  width: 100%;
}

.h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0 0 16px 0;
  color: #0a314a;
  background: (linear-gradient(84.16deg, #1b81bf -21.04%, #0a314a 104.92%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include tablet {
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 20px 0;
  }
  @include tablet {
    font-size: 40px;
    line-height: 60px;
    margin: 0 0 40px 0;
  }
}

.catalog {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  @include tablet {
  }
  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
}

.slide {
  display: flex;
  flex-direction: column;
  width: 140px;
  border-radius: 10px;
  background: white;
  box-shadow: 8px 15px 15px 0px #1b81bf1a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  @include tablet {
    width: 153px;
  }
  @include laptop {
    width: 300px;
    padding: 16px;
  }
}

.img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;

  @include tablet {
    // width: 108px;
  }
  @include laptop {
    // width: 180px;
  }
}

.btn {
  text-decoration: none;
  margin:  0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: $secondary-text-blue;
  background: none;
  border: none;
  border-radius: 30px;
  padding: 8px 0;
  z-index: 100;
  text-decoration: none;
  cursor: pointer;
  @include tablet {
    padding: 10px 0;
    width: 186px;
    margin: 16px 0 0 0;
    img {
      padding-left: 4px;
      color: $secondary-text-blue;
    }
  }
  @include laptop {
    border: 1px solid $secondary-text-blue;
    border-radius: 30px;
    padding: 16px 32px;
    z-index: 100;
    img {
      padding-left: 4px;
    }
  }
}
