@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $primary-bg;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 50px 0 0;
  padding-bottom: 132px;
  width: 328px;
  @include tablet {
    width: 592px;
  }
  @include laptop {
    width: 1200px;
    margin: 100px 0 0;
    padding-bottom: 132px;
  }
}

.applications {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include tablet {
    width: auto;
  }
  @include laptop {
    justify-content: space-between;
    flex-direction: row;
  }
}

.connection {
  width: 100%;
  @include laptop {
    width: 486px;
  }
}

.h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0 0 16px;
  @include tablet {
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 20px;
  }
  @include laptop {
    font-size: 40px;
    line-height: 60px;
    margin: 0 0 30px;
  }
}

.border {
  padding: 0 0 4px;
  border-bottom: 1px solid $secondary-text-blue;
  @include tablet {
    padding: 0 0 8px;
    border-bottom: 2px solid $secondary-text-blue;
  }
}

.paragraph {
  font-weight: 400;
  line-height: 21px;
  margin: 0 0 24px;
  opacity: 70%;
  font-size: 14px;
  @include tablet {
    margin: 0 0 40px;
  }
  @include laptop {
    line-height: 24px;
  }
}

.connect {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }
  @include laptop {
    display: flex;
    flex-direction: column;
  }
}

.address {
  display: flex;
  flex-direction: column;
  @include laptop {
    flex-direction: row;
  }
}

.img {
  @include tablet {
    margin-right: 8px;
  }
}

.icons {
  padding: 15px 17px;
  border: none;
  border-radius: 100%;
  background-color: $secondary-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  @include tablet {
    padding: 18px 20px;
  }
}

.paragraph1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  opacity: 90%;
  margin: 0 0 4px;
  @include laptop {
    font-size: 18px;
    line-height: 27px;
  }
}

.paragraph2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  opacity: 70%;
  margin: 0;
  @include laptop {
    font-size: 16px;
    line-height: 24px;
  }
}

.near {
  display: flex;
  gap: 30px;
  margin-top: 10px;

  @include tablet {
    gap: 80px;
    margin: 0;
  }
  @include laptop {
    margin-top: 24px;
    gap: 30px;
  }
}

.email {
  padding: 17px 17px;
  border: none;
  border-radius: 100%;
  background-color: $secondary-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  @include tablet {
    padding: 20px 18px;
  }
}

.application {
  width: 280px;
  padding: 24px;
  background-color: $secondary-bg;
  border-radius: 10px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include tablet {
    width: 440px;
    padding: 40px 76px;
  }
  @include laptop {
    width: 508px;
    padding: 48px 40px;
    margin: 0;
  }
}

.h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 16px;
  @include tablet {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 23px;
  }
  @include laptop {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 42px;
  }
}

.name {
  width: 280px;
  outline: none;
  border: 1px solid #ffffff;
  margin: 0 0 16px;
  border-radius: 30px;
  color: #ffffff;
  opacity: 70%;
  background: none;
  padding: 8px;
  @include tablet {
    width: 440px;
    padding: 13px;
  }
  @include laptop {
    width: 477px;
    padding: 15px;
  }
}

::-webkit-input-placeholder {
  color: #ffffff;
}

.phoneIbtn {
  @include tablet {
    display: flex;
    gap: 25px;
  }
}

.phone {
  width: 280px;
  outline: none;
  border: 1px solid #ffffff;
  border-radius: 30px;
  color: #ffffff;
  opacity: 70%;
  background: none;
  padding: 8px;

  @include tablet {
    width: 239px;
    padding: 15px;
  }
  @include laptop {
    width: 252px;
    margin: 0 0 0;
  }
}

.btn {
  width: 297px;
  background: white;
  color: $secondary-text-blue;
  border-radius: 30px;
  border: 4px solid #ffffff33;
  padding: 8px 0px;
  margin-top: 16px;
  @include tablet {
    width: 180px;
    margin: 0;
  }
  @include laptop {
    width: 196px;
    padding: 16px 32px;
  }
}

.map {
  margin-top: 40px;
  width: 100%;
  position: relative;
  @include tablet {
    margin-top: 100px;
  }
  @include laptop {
  }
}

.iframe {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  @include tablet {
  }
  @include laptop {
  }
}

.list {
  position: absolute;
  padding: 24px;
  width: 280px;
  top: 45%;
  left: 0;
  background-color: white;
  border-radius: 10px;
  @include tablet {
    padding: 20px;
    width: 331px;
    top: 20%;
  }
  @include laptop {
    padding: 24px;
    width: 395px;
    top: 10%;
    left: 5%;
  }
}

.h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: black;
  margin: 0 0 16px;
  @include tablet {
    font-size: 20px;
    line-height: 30px;
  }
  @include laptop {
    font-size: 24px;
    line-height: 36px;
  }
}

.branch {
  margin: 10px 0;
  display: flex;
  @include tablet {
    margin: 16px 0;
  }
}

.flex {
  display: flex;
  gap: 4px;
}

.image {
  margin-right: 8px;
}

.icon {
  width: 36px;
  height: 36px;
}

.phoneText {
  margin-left: 4px;
  @include tablet {
    margin-left: 10px;
  }
}

.paragraph3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #141414;

  margin: 0 0 4px;
  @include tablet {
    line-height: 21px;
    padding: 7.5px 0;
  }
  @include laptop {
  }
}

.iconPhone {
  margin-right: 4px;
  @include tablet {
    margin-right: 10px;
  }
}
.number {
  display: flex;
}

.paragraph4 {
  margin: 0;
  color: $secondary-text-blue;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
