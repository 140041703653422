@import 'src/styles/variables';
@import 'src/styles/_mixins.scss';

.reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $secondary-bg-white;
  @include laptop {
  }
}

.wrapper {
  width: 328px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  @include tablet {
    width: 592px;
  }
  @include laptop {
    width: 1200px;
    margin: 100px 0;
  }
}

.h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #0a314a;
  background: (linear-gradient(84.16deg, #1b81bf -21.04%, #0a314a 104.92%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  @include tablet {
    font-size: 32px;
    line-height: 48px;
  }
  @include laptop {
    font-size: 40px;
    line-height: 60px;
  }
}

.swiper {
  width: 100%;
  margin-top: 16px;
  @include tablet {
  }
  @include laptop {
    margin-top: 20px;
  }
}

.mySwiper {
  :global(.swiper-wrapper) {
    padding-bottom: 45px;
  }
  :global(.reviews_card__Zuk4L) {
    position: relative;
  }
  :global(.swiper-slide) {
    padding-top: 60px;
  }
  :global(.swiper-pagination-bullet) {
    padding: 1px;
  }
  :global(.swiper-button-prev) {
    color: $secondary-text-blue;
    top: 94%;
    left: 78%;
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding: 15px 10px 10px;
    z-index: 20;
  }
  :global(.swiper-button-next) {
    color: $secondary-text-blue;
    top: 94%;
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding: 15px 10px 10px;
    z-index: 20;
  }
  :global(.swiper-button-prev:after) {
    top: 94%;
    font-size: 14px;
  }
  :global(.swiper-button-next:after) {
    top: 94%;
    font-size: 14px;
  }
  @include tablet {
    :global(.swiper-pagination-bullet) {
      padding: 2px;
    }
    :global(.swiper-button-prev) {
      color: $secondary-text-blue;
      top: 94%;
      left: 87%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 15px 10px 10px;
      z-index: 20;
    }
    :global(.swiper-button-next) {
      color: $secondary-text-blue;
      top: 94%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 15px 10px 10px;
      z-index: 20;
    }
  }
  @include laptop {
    :global(.swiper-wrapper) {
      padding-bottom: 100px;
    }
    :global(.swiper-slide) {
      padding-top: 60px;
    }
    :global(.swiper-pagination-bullet) {
      padding: 3px;
    }
    :global(.swiper-button-prev) {
      color: white;
      top: 94%;
      left: 91%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 10px;
      background: $secondary-text-blue;
      border-radius: 100%;
      z-index: 20;
    }
    :global(.swiper-button-next) {
      color: white;
      top: 94%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 10px;
      background: $secondary-text-blue;
      border-radius: 100%;
      z-index: 20;
    }
    :global(.swiper-button-prev:after) {
      top: 94%;
      font-size: 10px;
    }
    :global(.swiper-button-next:after) {
      top: 94%;
      font-size: 10px;
    }
  }
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  padding: 50px 24px 32px;

  @include tablet {
    padding: 70px 22px 40px;
  }
  @include laptop {
   
    padding: 84px 35px 40px;
  }
}

.img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: none;
  position: absolute;
  top: 0%;
  @include tablet {
    width: 100px;
    height: 100px;
  }
  @include laptop {
    width: 120px;
    height: 120px;
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: $secondary-text-blue;
  margin: 0 0 8px 0;
  @include laptop {
    font-size: 20px;
    line-height: 24px;
  }
}

.paragraph1 {
  font-weight: 500;
  line-height: 16px;
  opacity: 40%;
  color: #141414;
  margin: 0 0 25px;
  font-size: 14px;
  @include laptop {
    font-size: 16px;
    line-height: 19px;
  }
}
.paragraph2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #141414;
  text-align: center;
  margin: 0;
  max-height: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
}
