@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.spareParts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $primary-bg;
  @include laptop {
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 328px;
  margin: 50px 0;
  @include tablet {
    width: 592px;
  }
  @include laptop {
    width: 1200px;
    margin: 100px 0;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  @include tablet {
    margin-bottom: 40px;
  }
  @include laptop {
  }
}

.h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: white;
  margin: 0;
  white-space: nowrap;
  @include tablet {
    font-size: 32px;
    line-height: 38px;
  }
  @include laptop {
    font-size: 40px;
    line-height: 48px;
  }
}

.btn {
  display: none;
  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: none;
    border: none;
    font-size: 14px;
    img {
      padding-left: 4px;
    }
  }
  @include laptop {
    font-size: 16px;
    color: white;
    background: none;
    border: 1px solid white;
    border-radius: 30px;
    padding: 16px 32px;
    img {
      padding-left: 4px;
    }
  }
}

.swiper {
  width: 100%;
}

.mySwiper {
  :global(.swiper-wrapper) {
    padding-bottom: 60px;
  }
  :global(.swiper-pagination-bullet) {
    padding: 1px;
  }
  :global(.swiper-button-prev) {
    color: $secondary-text-blue;
    top: 94%;
    left: 80%;
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding: 10px;
    border-radius: 100%;
    z-index: 20;
  }
  :global(.swiper-button-next) {
    color: $secondary-text-blue;
    top: 94%;
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding: 10px;
    border-radius: 100%;
    z-index: 20;
  }
  :global(.swiper-button-prev:after) {
    top: 94%;
    font-size: 10px;
  }
  :global(.swiper-button-next:after) {
    top: 94%;
    font-size: 10px;
  }
  @include tablet {
    :global(.swiper-pagination-bullet) {
      padding: 2px;
    }
    :global(.swiper-button-prev) {
      color: $secondary-text-blue;
      top: 94%;
      left: 86%;
      font-size: 20px;
      width: 20px;
      height: 20px;
      padding: 10px;
      border-radius: 100%;
      z-index: 20;
    }
    :global(.swiper-button-next) {
      color: $secondary-text-blue;
      top: 94%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 10px;
      border-radius: 100%;
      z-index: 20;
    }
   }
  @include laptop {
    :global(.swiper-wrapper) {
      padding-bottom: 100px;
    }
    :global(.swiper-pagination-bullet) {
      padding: 3px;
    }
    :global(.swiper-button-prev) {
      color: white;
      top: 94%;
      left: 91%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 10px;
      background: $secondary-text-blue;
      border-radius: 100%;
      z-index: 20;
    }
    :global(.swiper-button-next) {
      color: white;
      top: 94%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 10px;
      background: $secondary-text-blue;
      border-radius: 100%;
      z-index: 20;
    }
  }
}

.img {
  width: 156px;
  @include tablet {
    width: 184px;
  }
  @include laptop {
    width: 282px;
  }
}

.paragraphs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4px;
  @include tablet {
    margin-top: 8px;
  }
}

.paragraph1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-text-blue;
  margin: 0;
  @include laptop {
    font-size: 24px;
    line-height: 36px;
  }
}
.paragraph2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  opacity: 50%;
  @include laptop {
    font-size: 16px;
    line-height: 19px;
  }
}
.paragraph3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0 0;
  font-weight: 500;
  line-height: 21px;
  font-size: 14px;
  img {
    width: 24px;
    height: 24px;
  }
  @include tablet {
    margin: 16px 0 0;
    gap: 12px;
  }
  @include laptop {
    line-height: 24px;
    font-size: 16px;
  }
}
