@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.project {
  background-image: url("../../../public/assets/img/project-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  @include tablet {
    padding-bottom: 50px;
  }
  @include laptop {
    padding-bottom: 120px;
  }
}

.wrapper {
  width: 328px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  @include tablet {
    width: 592px;
  }
  @include laptop {
    width: 1200px;
    margin-top: 100px;
  }
}

.h5 {
  color: $secondary-text-blue;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin: 0 0 4px;
  @include tablet {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px;
  }
  @include laptop {
    font-size: 20px;
    line-height: 30px;
  }
}

.near {
  display: flex;
  flex-direction: column;
  @include laptop {
  }
}

.text {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include laptop {
    width: 510px;
  }
}

.h1 {
  margin: 0 0 16px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #0a314a;
  background: (linear-gradient(84.16deg, #1b81bf -21.04%, #0a314a 104.92%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include tablet {
    margin: 0 0 20px;
    font-size: 32px;
    line-height: 38px;
  }
  @include laptop {
    margin: 0 0 24px;
    font-size: 40px;
    line-height: 48px;
  }
}

.paragraph {
  margin: 0;
  font-weight: 400;
  line-height: 21px;
  font-size: 14px;
  color: #141414;

  @include laptop {
    font-size: 16px;
    line-height: 24px;
  }
}

.swiper {
  width: 100%;
  margin-top: 20px;
  @include laptop {
    width: auto;
    margin: 0;
    margin-left: 124px;
    overflow: visible !important;
    position: absolute;
    right: 0;
    left: 43.5%;
  }
}

.width {
  border-radius: 10px;
  border: 2px;
  width: 156px;
  height: 150px;
  @include tablet {
    width: 229px;
    height: 200px;
  }
  @include laptop {
    width: 274px;
    height: 267px;
  }
}

.mySwiper {
  :global(.swiper-wrapper) {
    padding-bottom: 50px;
  }

  :global(.swiper-pagination-bullet) {
    padding: 1px;
  }
  :global(.swiper-slide) {
    width: 274px;
  }
  :global(.swiper-button-prev) {
    color: $secondary-text-blue;
    top: 94%;
    left: 78%;
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding: 10px;
    border-radius: 100%;
    z-index: 20;
  }
  :global(.swiper-button-next) {
    color: $secondary-text-blue;
    top: 94%;
    font-size: 10px;
    width: 20px;
    height: 20px;
    padding: 10px;
    border-radius: 100%;
    z-index: 20;
  }
  :global(.swiper-button-prev:after) {
    top: 94%;
    font-size: 15px;
  }
  :global(.swiper-button-next:after) {
    top: 94%;
    font-size: 15px;
  }
  @include tablet {
    :global(.swiper-button-prev) {
      color: $secondary-text-blue;
      top: 94%;
      left: 85%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 10px;
      border-radius: 100%;
      z-index: 20;
    }
  }
  @include laptop {
    :global(.swiper-pagination-bullets.swiper-pagination-horizontal) {
      left: -10%;
    }
    :global(.swiper-pagination-bullet) {
      padding: 3px;
    }
    :global(.swiper-button-prev) {
      color: white;
      top: 94%;
      left: 60%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 10px;
      background: $secondary-text-blue;
      border-radius: 100%;
      z-index: 20;
    }
    :global(.swiper-button-next) {
      color: white;
      top: 94%;
      left: 70%;
      font-size: 10px;
      width: 20px;
      height: 20px;
      padding: 10px;
      background: $secondary-text-blue;
      border-radius: 100%;
      z-index: 20;
    }
    :global(.swiper-button-prev:after) {
      top: 94%;
      font-size: 10px;
    }
    :global(.swiper-button-next:after) {
      top: 94%;
      font-size: 10px;
    }
  }
}

.slideP {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: black;
  @include tablet {
    font-size: 16px;
    margin: 0;
    position: absolute;
    bottom: 10%;
    left: 5%;
    color: white;
  }
  @include laptop {
  }
}
