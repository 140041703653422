@import "src/styles/variables";
@import "src/styles/_mixins.scss";

.consultation {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-bg;
  width: 100%;
  @include laptop {
  }
}

.wrapper {
  background-image: url("../../../public/assets/img/bg-consultation.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 328px;
  margin-bottom: 24px;
  border-radius: 10px;

  @include tablet {
    width: 592px;
    margin-bottom: 40px;

  }
  @include laptop {
    width: 1200px;
    margin-bottom: 100px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 328px;
  margin: 24px 0;
  @include tablet {
    width: 439px;
    margin: 40px 0;
  }
  @include laptop {
    width: 600px;
    margin: 48px 0;
  }
}

.h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 20px;
  @include tablet {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 24px;
  }
  @include laptop {
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 32px;
  }
}

.border {
  border-bottom: 1px solid $secondary-bg;
  padding-bottom: 4px;
  @include laptop {
    border-bottom: 2px solid $secondary-bg;
    padding-bottom: 8px;
  }
}
.paragraph {
  margin: 0 0 24px;
  font-weight: 400;
  line-height: 21px;
  font-size: 14px;
  text-align: center;
  opacity: 70%;
  @include laptop {
    font-size: 16px;
    margin: 0 0 40px;
    line-height: 24px;
  }
}

.btn {
  padding: 12px 24px;
  background: $secondary-bg;
  border-radius: 30px;
  font-weight: 500;
  line-height: 17px;
  font-size: 14px;
  border: none;
  color: white;
  @include tablet {
    padding: 16px 32px;
  }
  @include laptop {
    font-size: 16px;
  }
}
